// col is-3:        is 3 grids of 12-grid
// col is-2-from-5: is 2/5th of width

//--------------------------------------------------------------------------
// Structure
//--------------------------------------------------------------------------

.template // root div of page
  z-index: $z-index-base

.segment
  // fullwidth, no padding or margin on default

.content
  margin: 0 auto
  padding: m(2) content-padding()
  width: content-width()
  max-width: 100%
  background-color: $content-color
  &.is-fullwidth
    width: 100%

+md
  .content
    padding: m(2) content-padding('md')
    width: content-width('md')

+sm
  .content
    padding: m(2) content-padding('sm')
    width: content-width('sm')

+xs
  .content
    padding: m(2) content-padding('xs')
    width: content-width('xs')

//--------------------------------------------------------------------------
// Visibility
//--------------------------------------------------------------------------

.is-hidden
  display: none !important

+lg-only
  .is-hidden-lg-only
    display: none !important

+md
  .is-hidden-md
    display: none !important

+md-only
  .is-hidden-md-only
    display: none !important

+sm
  .is-hidden-sm
    display: none !important

+sm-only
  .is-hidden-sm-only
    display: none !important

+xs
  .is-hidden-xs
    display: none !important

+xs-only
  .is-hidden-xs-only
    display: none !important


//--------------------------------------------------------------------------
// Grid system
//--------------------------------------------------------------------------

.row
  display: flex
  flex-direction: row
  margin-left: -(col-gap() / 2)
  margin-right: -(col-gap() / 2)
  &.is-row
    flex-direction: row
  &.is-row-reverse
    flex-direction: row-reverse
  &.is-column
    flex-direction: column
  &.is-column-reverse
    flex-direction: column-reverse
  &.is-box // allowes row to have a background, that is not wider than content
    margin-left: 0
    margin-right: 0
  .col
    display: block
    flex-basis: 0
    flex-grow: 1
    flex-shrink: 1
    padding: col-gap() / 2
    @for $i from 1 through 12
      &.is-#{$i}
        flex: none
        flex-basis: percentage($i / 12)
      &.is-offset-#{$i}
        margin-left: percentage($i / 12)
    @for $i from 1 through 10
      @for $j from 1 through $i
        &.is-#{$j}-from-#{$i}
          flex: none
          flex-basis: percentage((10 / $i * $j) / 10)
        &.is-offset-#{$j}-from-#{$i}
          margin-left: percentage((10 / $i * $j) / 10)
  &.no-gap
    margin-left: 0
    margin-right: 0
    >.col
      padding: 0
  &.is-multiline
    flex-wrap: wrap
  &.is-centered
    justify-content: center
  &.is-vcentered
    align-items: center
  &.is-visible
    display: flex
  &.is-hidden
    display: none

+md
  .row
    margin-left: -(col-gap('md') / 2)
    margin-right: -(col-gap('md') / 2)
    .col
      padding: col-gap('md') / 2
      @for $i from 1 through 12
        &.is-#{$i}-md
          flex: none
          flex-basis: percentage($i / 12)
        &.is-offset-#{$i}-md
          margin-left: percentage($i / 12)
      @for $i from 1 through 10
        @for $j from 1 through $i
          &.is-#{$j}-from-#{$i}-md
            flex: none
            flex-basis: percentage((10 / $i * $j) / 10)
          &.is-offset-#{$j}-from-#{$i}-md
            margin-left: percentage((10 / $i * $j) / 10)
      &.is-no-offset-md
        margin-left: 0
    &.no-gap,
    &.no-gap-md
      margin-left: 0
      margin-right: 0
      >.col
        padding: 0
    &.is-multiline-md
      flex-wrap: wrap
    &.is-centered-md
      justify-content: center
    &.is-vcentered-md
      align-items: center

+sm
  .row
    margin-left: -(col-gap('sm') / 2)
    margin-right: -(col-gap('sm') / 2)
    .col
      padding: col-gap('sm') / 2
      @for $i from 1 through 12
        &.is-#{$i}-sm
          flex: none
          flex-basis: percentage($i / 12)
        &.is-offset-#{$i}-sm
          margin-left: percentage($i / 12)
      @for $i from 1 through 10
        @for $j from 1 through $i
          &.is-#{$j}-from-#{$i}-sm
            flex: none
            flex-basis: percentage((10 / $i * $j) / 10)
          &.is-offset-#{$j}-from-#{$i}-sm
            margin-left: percentage((10 / $i * $j) / 10)
      &.is-no-offset-sm
        margin-left: 0
    &.no-gap,
    &.no-gap-sm
      margin-left: 0
      margin-right: 0
      >.col
        padding: 0
    &.is-multiline-sm
      flex-wrap: wrap
    &.is-centered-sm
      justify-content: center
    &.is-vcentered-sm
      align-items: center

+xs
  .row
    margin-left: -(col-gap('xs') / 2)
    margin-right: -(col-gap('xs') / 2)
    &.is-box // allowes row to have a background, that is not wider than content
      margin-left: - content-padding('xs')
      margin-right: - content-padding('xs')
    .col
      padding: col-gap('xs') / 2
      &.is-no-offset-xs
        margin-left: 0
    &:not(.is-xs)
      display: block
      .col
        margin-left: 0
        @for $i from 1 through 12
          &.is-#{$i}-xs
            flex-basis: 100%
          &.is-offset-#{$i}-xs
            margin-left: 0
        @for $i from 1 through 10
          @for $j from 1 through $i
            &.is-#{$j}-from-#{$i},
            &.is-#{$j}-from-#{$i}-xs
              flex-basis: 100%
            &.is-offset-#{$j}-from-#{$i},
            &.is-offset-#{$j}-from-#{$i}-xs
              margin-left: 0
        &.is-no-offset-xs
          margin-left: 0
    &.is-xs
      display: flex
      .col
        @for $i from 1 through 12
          &.is-#{$i}-xs
            flex-basis: percentage($i / 12)
          &.is-offset-#{$i}-xs
            margin-left: percentage($i / 12)
        @for $i from 1 through 10
          @for $j from 1 through $i
            &.is-#{$j}-from-#{$i}-xs
              flex: none
              flex-basis: percentage((10 / $i * $j) / 10)
            &.is-offset-#{$j}-from-#{$i}-xs
              margin-left: percentage((10 / $i * $j) / 10)
        &.is-no-offset-xs
          margin-left: 0
    &.no-gap,
    &.no-gap-xs
      margin-left: 0
      margin-right: 0
      >.col
        padding: 0
    &.is-multiline-xs
      flex-wrap: wrap
    &.is-centered-xs
      justify-content: center
    &.is-vcentered-xs
      align-items: center
