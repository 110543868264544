@font-face
  font-family: 'hausvier_icons'
  src: url('../assets/fonts/icons/hausvier.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'titillium_bold'
  src: url('../assets/fonts/titillium/titillium-bold-webfont.woff2') format('woff2'), url('../assets/fonts/titillium/titillium-bold-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face 
  font-family: 'titillium_light'
  src: url('../assets/fonts/titillium/titillium-thin-webfont.woff2') format('woff2'), url('../assets/fonts/titillium/titillium-thin-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'titillium_semibold'
  src: url('../assets/fonts/titillium/titillium-semibold-webfont.woff2') format('woff2'), url('../assets/fonts/titillium/titillium-semibold-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'titillium_regular'
  src: url('../assets/fonts/titillium/titillium-regular-webfont.woff2') format('woff2'), url('../assets/fonts/titillium/titillium-regular-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'titillium_italic'
  src: url('../assets/fonts/titillium/titillium-regularitalic-webfont.woff2') format('woff2'), url('../assets/fonts/titillium/titillium-regularitalic-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal