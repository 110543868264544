//--------------------------------------------------------------------------
// Text, Font, Typo
//--------------------------------------------------------------------------

.reset
  +font('default', 'regular')
  color: $text-color    

h1.typo,
h1.text,
.typo h1,
.text h1
  +font('bigger', 'regular')
  color: $headline-color

h2.typo,
h2.text,
.typo h2,
.text h2
  +font('big', 'regular')
  color: $headline-color

h3.typo,
h3.text,
.typo h3,
.text h3
  +font()
  color: $headline-color

h4.typo,
h4.text,
h5.typo,
h5.text,
h6.typo,
h6.text
  @extend .reset

p.typo,
p.text,
.typo p,
.text p,
.block.text
  @extend .reset

hr.typo,
hr.text,
.typo hr,
.text hr
  height: 1px
  background-color: transparent
  background-image: linear-gradient(to right, $grey-light 60%, transparent 40%)
  background-size: m(2) 100%

// additional styles
.is-strong.typo,
.is-strong.text,
.typo .is-strong,
.text .is-strong,
strong.typo,
strong.text,
.typo strong,
.text strong
  +font('semibold')
  *
    +font('semibold')

.is-teaser.typo,
.is-teaser.text,
.typo .is-teaser,
.text .is-teaser
  +font('medium')

.is-small.typo,
.is-small.text,
.typo .is-small,
.text .is-small
  +font('small')

em.typo,
em.text,
.typo em,
.text em
  +font('italic')

.is-soft.typo
  opacity: $opacity-soft
.is-softer.typo
  opacity: $opacity-softer
.block.typo
  @extend .reset

// same styles with margin/padding
h1.text,
.text h1
  margin: m(8) 0 m(2) 0
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

h2.text,
.text h2
  margin: m(4) 0 m(1) 0
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

h3.text,
.text h3
  margin: m(2) 0 m(1) 0
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

h4.text,
.text h4,
h5.text,
.text h5,
h6.text,
.text h6
  margin: m(2) 0 m(1) 0
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

p.text,
.text p
  margin: 0 0 m(2) 0
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

hr.text,
.text hr
  margin: m(3) 0 m(4) 0

// any other div-element inside block flow
.block.text,
.text .block
  margin: m(2) 0 m(2) 0
  &:first-child
    margin-top: 0
  &:last-child
    margin-bottom: 0

//--------------------------------------------------------------------------
// Special text styles
//--------------------------------------------------------------------------

.legend
  +font('default', 'bold')
  color: $text-color
  text-transform: uppercase

//--------------------------------------------------------------------------
// Lists
//--------------------------------------------------------------------------

ol.typo,
ol.text,
.typo ol,
.text ol
  list-style-position: outside
  &:not([type])
    list-style-type: decimal
    &.is-lower-alpha
      list-style-type: lower-alpha
    &.is-lower-roman
      list-style-type: lower-roman
    &.is-upper-alpha
      list-style-type: upper-alpha
    &.is-upper-roman
      list-style-type: upper-roman

ul.typo,
ul.text,
.typo ul,
.text ul
  list-style: disc outside
  ul
    list-style-type: circle
    margin-top: m(1)
    ul
      list-style-type: square

li.typo,
li.text,
.typo li,
.text li
  @extend .reset

ol.text,
.text ol
  margin: 0 0 m(2) m(4)

ul.text,
.text ul
  margin: 0 0 m(2) m(4)

li.text,
.text li
  padding: 0 0 0 0

//--------------------------------------------------------------------------
// Quotes
//--------------------------------------------------------------------------

blockquote.typo,
blockquote.text,
.typo blockquote,
.text blockquote
  +font('medium', 'italic')
  color: $quote-color
  & > *
    +font('medium', 'italic')
    color: $quote-color

blockquote.text,
.text blockquote
  margin-left: 2em
  margin-top: 1em
  margin-bottom: 1em
  padding-left: 1em
  margin-right: 3.7em
  border-left: .7em solid $border-color
  > *
    +font('medium', 'italic')
    color: $quote-color

+xs
  .blockquote.text,
  .text blockquote
      margin-left: 1em
      padding-left: .5em
      margin-right: 2.2em
      border-left: .7em solid $border-color


//--------------------------------------------------------------------------
// Code
//--------------------------------------------------------------------------

code.typo,
code.text,
.typo code,
.text code,
pre.typo,
pre.text,
.typo pre,
.text pre
  font-family: $font-family-monospace
  font-size: ($font-size-default * .9)
  line-height: $line-height-default
  color: $text-color

code.text,
.text code
  padding: m(.5)
  border: 1px solid $border-color
  border-radius: $radius
  background-color: $white-darker

pre.text,
.text pre
  padding: m(1)
  border: 1px solid $border-color
  border-radius: $radius
  background-color: $white-darker
  code
    padding: 0
    border: none
    border-radius: none
    background-color: transparent

//--------------------------------------------------------------------------
// Tables
//--------------------------------------------------------------------------

table.typo,
table.text,
.typo table,
.text table
  td,
  th
    @extend .reset
    vertical-align: top
  thead
    td,
    th
      +font('medium')
      text-align: left
  tfoot
    td,
    th
      +font('medium')
      text-align: left

table.text,
.text table
  width: 100%
  margin: 0 0 m(2) 0
  td,
  th
    border: 1px solid $border-color
    border-width: 0 0 1px
    padding: m(1) m(1) m(1) 0
  thead
    td,
    th
      border-width: 0 0 2px
  tfoot
    td,
    th
      border-width: 2px 0 0
  tbody
    tr
      &:last-child
        td,
        th
          border-bottom-width: 0

//--------------------------------------------------------------------------
// Icon-Classes
//--------------------------------------------------------------------------

[class^="icon-"],
[class*=" icon-"]
  +icon

@each $key, $value in $icons
  .icon-#{$key}:before
    content: $value !important