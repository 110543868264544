//--------------------------------------------------------------------------
// Mixins
//--------------------------------------------------------------------------

=button-color($text, $background)
  color: $text
  background-color: $background
  border-color: $background
  &:not(.is-disabled):hover
    background-color: darken($background, 20%)
    border-color: darken($background, 20%)
  &.is-outline,
  &.is-icon
    color: $background
    background-color: transparent
    &:not(.is-disabled):hover
      color: darken($background, 20%)
      background-color: transparent
      border-color: darken($background, 20%)
  &.is-icon 
    border-color: transparent
    &:not(.is-disabled):hover
      border-color: transparent
  &.is-disabled
    opacity: .4
    cursor: default

// behaves vice-versa: darker in normal state, white on hover
=button-color-white()
  color: $grey
  background-color: darken($white, 10%)
  border-color: darken($white, 10%)
  opacity: .7
  &:not(.is-disabled):hover
    background-color: $white
    border-color: $white
    opacity: 1
  &.is-outline,
  &.is-icon
    color: darken($white, 20%)
    background-color: transparent
    border-color: darken($white, 20%)
    &:not(.is-disabled):hover
      color: $white
      background-color: transparent
      border-color: $white
  &.is-icon 
    border-color: transparent
    &:not(.is-disabled):hover
      border-color: transparent
  &.is-disabled
    opacity: .25
    cursor: default

=button-size($font-size)
  padding: 0 .6em
  height: $button-height
  font-size: $font-size * 0.8
  &:before
    font-size: $font-size
  &.is-narrow
    padding: 0 .2em
    height: $button-height * .7
  &.is-icon
    padding: 0
    height: $button-height
    width: $button-height
    justify-content: center
    align-items: center
    &:before
      padding: 0
    &.is-narrow
      height: $button-height * .7
      width: $button-height * .7

//--------------------------------------------------------------------------
// Styles
//--------------------------------------------------------------------------

// base settings
.button
  display: inline-flex
  align-items: center
  flex-direction: row
  +font('bold')
  border: 1px solid
  border-radius: $radius-small
  text-transform: uppercase
  white-space: nowrap
  text-overflow: ellipsis
  cursor: pointer
  &:before
    padding: 0 .2em 0 0
  &.pull-right
    flex-direction: row-reverse
    &:before
      padding: 0 0 0 .2em

// colors / styles
.button
  +button-color($white, $grey)
  &.is-primary
    +button-color($white, $primary-color)
  &.is-seconday
    +button-color($white, $seconday-color)
  &.is-warning
    +button-color($white, $warning-color)
  &.is-success
    +button-color($white, $success-color)
  &.is-white
    +button-color-white()
  &.is-text
    +button-color($text-color, transparent)
    &:before
      font-size: $font-size-default * 0.8

// sizes
.button
  +button-size($font-size-default)
  &.is-small
    +button-size($font-size-small)
  &.is-medium
    +button-size($font-size-medium)
  &.is-big
    +button-size($font-size-big)