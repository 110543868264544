//--------------------------------------------------------------------------
// Mixins
//--------------------------------------------------------------------------

=input
  input,
  textarea,
  select
    -webkit-appearance: none
    -moz-appearance: none
    max-width: 100%
    width: 100%
    padding: 2px $form-input-indent
    font-size: $form-font-size
    line-height: $form-line-height
    color: $form-color
    border: 1px solid $form-border-color
    box-shadow: none
    outline: none
    border-radius: $form-border-radius
    background-color: $form-background-color
    +placeholder
      color: $form-placeholder-color
    &:focus
      border-color: $form-control-color
      box-shadow: 0 0 0 0.125em rgba($form-control-color, 0.25)
  &.is-disabled // disabled="disabled" must be set to the input also
    input,
    textarea,
    select
      +unselectable
      color: $form-disabled-color
      background-color: $form-disabled-background-color
      cursor: default
      &:focus
        border-color: $form-border-color
        box-shadow: none
  &:not(.is-disabled)
    &.has-error
      input,
      textarea,
      select
        padding: 1px $form-input-indent
        border: 2px solid $form-error-color
        &:focus
          border-color: $form-error-color
          box-shadow: 0 0 0 0.125em rgba($form-error-color, 0.25)

//--------------------------------------------------------------------------
// Styles
//--------------------------------------------------------------------------

.label
  display: inline-flex
  align-items: flex-start
  //no margin-right in col childs
  padding: 3px 0
  font-size: $form-font-size
  line-height: $form-line-height
  &.has-error
    color: $form-error-color
  &.is-hidden
    display: none
  &.is-visible
    display: inline-flex

.control
  position: relative
  display: flex
  align-items: flex-start

  &.text
    +input

  &.textarea
    +input
    textarea
      resize: vertical
      &:not([rows])
        height: $form-textarea-height
        min-height: $form-textarea-height
        max-height: $form-textarea-height * 3
      &[rows]
        height: initial
      &:focus
        border-color: $form-control-color
        box-shadow: 0 0 0 0.125em rgba($form-control-color, 0.25)
    &.has-fixed-size
      textarea
        resize: none
    &.is-small
      textarea
        &:not([rows])
          height: $form-textarea-height * .5
          min-height: $form-textarea-height * .5
          max-height: $form-textarea-height * 1.5
    &.is-medium
      textarea
        &:not([rows])
          height: $form-textarea-height * 1.5
          min-height: $form-textarea-height * 1.5
          max-height: $form-textarea-height * 4.5
    &.is-big
      textarea
        &:not([rows])
          height: $form-textarea-height * 2
          min-height: $form-textarea-height * 2
          max-height: $form-textarea-height * 6

  &.select
    +input
    &::after
      position: absolute
      display: block
      top: 50%
      right: .75em
      height: 0.625em
      width: 0.625em
      margin-top: -0.4em
      border: 4px solid $form-control-color
      border-right: 0
      border-top: 0
      border-radius: 2px
      pointer-events: none
      transform: rotate(-45deg)
      transform-origin: center
      content: " "
      z-index: 4
    select
      height: 100%
      cursor: pointer
      padding-right: 2em
      &::-ms-expand
        display: none
    &.is-disabled
      &::after
        border-color: $form-disabled-color
    &:not(.is-disabled)
      &.has-error
        &::after
          border-color: $form-error-color

  &.checkbox
    display: flex
    align-items: flex-start
    padding: 2px 0
    font-size: $form-font-size
    line-height: $form-line-height
    border-top: 1px solid transparent
    border-bottom: 1px solid transparent
    cursor: pointer
    input
      $margin-vertical: (($form-font-size * $form-line-height) - $form-font-size) / 2
      position: relative
      flex-shrink: 0
      width: $form-font-size
      height: $form-font-size
      margin: $margin-vertical $form-input-indent $margin-vertical 0
      border: 1px solid $form-border-color
      border-radius: $form-border-radius
      background-color: $form-background-color
      transform: translateY(1px)
      -webkit-appearance: none
      -moz-appearance: none
      outline: none
      cursor: pointer
      &:checked
        &:after
          position: absolute
          display: block
          top: 50%
          height: 0.625em
          width: 1.25em
          margin-top: -0.4em
          margin-left: 0.25em
          border: 4px solid $form-control-color
          border-right: 0
          border-top: 0
          border-radius: 2px
          pointer-events: none
          transform: rotate(-45deg)
          transform-origin: center
          content: " "
    &.is-inline
      display: inline-flex
      margin-right: $form-input-indent * 3
    &.is-disabled
      input
        background-color: $form-disabled-background-color
        &:checked
          &:after
            border-color: $form-disabled-color
    &.has-error:not(.is-disabled)
      color: $warning-color
      input
        border-color: $form-error-color
        background-color: $form-error-color
        &:checked
          &:after
            border-color: $white
      p
        color: $warning-color
        a
          color: $warning-color
          text-decoration: underline

  &.radio
    display: flex
    align-items: flex-start
    padding: 2px 0
    font-size: $form-font-size
    line-height: $form-line-height
    border-top: 1px solid transparent
    border-bottom: 1px solid transparent
    cursor: pointer
    input
      $margin-vertical: (($form-font-size * $form-line-height) - $form-font-size) / 2
      position: relative
      flex-shrink: 0
      width: $form-font-size
      height: $form-font-size
      margin: $margin-vertical $form-input-indent $margin-vertical 0
      border: 1px solid $form-border-color
      background-color: $form-background-color
      border-radius: $radius-rounded
      transform: translateY(1px)
      -webkit-appearance: none
      -moz-appearance: none
      outline: none
      cursor: pointer
      &:checked
        &:after
          position: absolute
          display: block
          top: 1px
          left: 1px
          bottom: 1px
          right: 1px
          border: 1px solid $white
          background-color: $form-control-color
          border-radius: $radius-rounded
          pointer-events: none
          content: " "
    &.is-inline
      display: inline-flex
      margin-right: $form-input-indent * 3
    &.is-disabled
      input
        background-color: $form-disabled-background-color
        &:checked
          &:after
            background-color: $form-disabled-color
    &.has-error:not(.is-disabled)
      input
        border-color: $form-error-color
        background-color: $form-error-color
        &:checked
          &:after
            border: 2px solid $white
            background-color: $form-error-color
.is-inline
  >.control
    &.radio
      display: inline-flex
      margin-right: $form-input-indent * 3
.is-disabled
  >.control
    &.radio
      input
        background-color: $form-disabled-background-color
        &:checked
          &:after
            background-color: $form-disabled-color
.has-error:not(.is-disabled)
  >.control
    &.radio
      input
        border-color: $form-error-color
        background-color: $form-error-color
        &:checked
          &:after
            border: 2px solid $white
            background-color: $form-error-color